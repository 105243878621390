import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import storeHeader from '../../components/storeHeader.vue';
import { showToast } from 'vant';
import { onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
export default {
  __name: 'index',
  setup(__props) {
    const provinces = require('province-city-china/dist/data.json');
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const data = reactive({
      areaList: {
        province_list: {
          110000: '广东省',
          120000: '浙江省'
        },
        city_list: {
          110100: '广州市',
          110200: '深圳市',
          120100: '杭州市',
          120200: '宁波市'
        },
        county_list: {
          110101: '天河区',
          110102: '海珠区',
          120102: '上城区',
          120103: '下城区',
          110201: '福田区',
          110202: '南山区',
          110203: '罗湖区',
          110204: '盐田区',
          110205: '龙岗区',
          110206: '宝安区',
          110207: '光明区',
          110208: '龙华区',
          110209: '坪山区',
          110210: '大peng区'
        }
      },
      AddressEditInfo: {},
      addtype: ''
    });
    const init = () => {
      store.state.userAddress.forEach(item => {
        if (item.id === Number(route.query.id)) {
          data.AddressEditInfo = item;
        }
      });
      if (route.query.type === 'edit') {
        data.addtype = '修改地址';
      } else {
        data.addtype = '新增地址';
      }
    };
    onMounted(() => {
      init();
    });
    const getprovinceList = type => {
      const s = provinces.filter(item => {
        if (type === 'province') {
          return item.city === 0;
        } else if (type === 'city') {
          return item.city !== 0 && item.area === 0;
        } else if (type === 'area') {
          return item.city !== 0 && item.area !== 0 && item.town === 0;
        }
      });
      var obj = {};
      s.map(item => {
        obj[item.code] = item.name;
      });
      if (type === 'city') {
        obj['110100'] = '北京市';
        obj['120100'] = '天津市';
        obj['310100'] = '上海市';
        obj['500100'] = '重庆市';
        obj['810100'] = '该地区不支持';
        obj['820100'] = '该地区不支持';
        obj['710100'] = '该地区不支持';
      }
      return obj;
    };
    const province = getprovinceList('province');
    const city = getprovinceList('city');
    const area = getprovinceList('area');
    data.areaList.province_list = province;
    data.areaList.city_list = city;
    data.areaList.county_list = area;
    // console.log(province)
    // console.log(provinces)

    const onSave = content => {
      if (route.query.type === 'add') {
        store.commit('addUserAddress', content);
      } else {
        store.commit('editUserAddress', content);
      }
      showToast('保存成功');
      setTimeout(() => {
        router.back();
      }, 1000);
    };
    const onDelete = content => {
      if (route.query.type === 'edit') {
        store.commit('deletetUserAddress', content);
      } else {}
      showToast('删除成功');
      setTimeout(() => {
        router.back();
      }, 1000);
    };
    return (_ctx, _cache) => {
      const _component_van_address_edit = _resolveComponent("van-address-edit");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(storeHeader, {
        title: data.addtype
      }, null, 8, ["title"]), _createElementVNode("div", null, [_createVNode(_component_van_address_edit, {
        "area-list": data.areaList,
        "show-delete": "",
        "show-set-default": "",
        "show-search-result": "",
        "address-info": data.AddressEditInfo,
        "search-result": _ctx.searchResult,
        "area-columns-placeholder": ['请选择', '请选择', '请选择'],
        onSave: onSave,
        onDelete: onDelete,
        onChangeDetail: _ctx.onChangeDetail
      }, null, 8, ["area-list", "address-info", "search-result", "onChangeDetail"])])], 64);
    };
  }
};